<template>
  <div class="">
    <center-title
      :title="acountMode == 'add' ? '新增审批' : '编辑审批'"
      :border="false"
    >
    </center-title>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="162px"
      class="ruleForm"
    >
      <el-form-item label="功能节点" prop="approvalType" class="mb30">
        <el-select
          v-model="ruleForm.approvalType"
          placeholder="请选择功能节点"
          style="width: 80%"
        >
          <el-option
            v-for="(item, index) in approvalTypeLists"
            :key="index"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <div v-if="this.$store.getters.userInfo.company_id != 57">
        <el-form-item label="审核节点" prop="earlyApproval" class="mb30">
          <el-select
            v-model="ruleForm.earlyApproval"
            placeholder="请选择功能节点"
            style="width: 80%"
          >
            <el-option
              v-for="(item, index) in managerLists"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核节点" prop="finalApproval" class="mb30">
          <el-select
            v-model="ruleForm.finalApproval"
            placeholder="请选择功能节点"
            style="width: 80%"
          >
            <el-option
              v-for="(item, index) in managerLists"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 这个的v-for是核心是重点，一定要明白！ -->
        <!-- <div v-for="(item, index) in array" :key="index">
						<el-form-item label="审核节点" prop="earlyApproval" class="mb30">
							<el-select v-model="ruleForm.finalApproval" placeholder="请选择功能节点" style="width: 80%;">
					<el-option v-for="(item, index) in managerLists" :key="index" :label="item.nickname" :value="item.id">
					</el-option>
				</el-select>
							<el-button type="danger" size="small" icon="el-icon-delete" circle
								@click="del(index)" style="width: 48px; margin-left: 10px;"></el-button>
						</el-form-item>
					</div> -->
        <el-form-item>
          <el-button
            type="primary"
            class="white md bg-primary"
            @click="submitForm('ruleForm', 0)"
          >
            {{ acountMode == "add" ? "新增" : "保存修改" }}
          </el-button>
          <el-button
            class="reset bg-center white md"
            @click="resetForm('ruleForm')"
            v-if="acountMode != 'edit'"
          >
            重置
          </el-button>

          <!-- <el-button style="margin-left: 30px;" type="success" @click="add">增加审批节点</el-button> -->
        </el-form-item>
      </div>

      <div v-if="this.$store.getters.userInfo.company_id == 57">
        <el-button
          style="margin-left: 620px"
          type="success"
          @click="submitForm('ruleForm', 1)"
          >增加审批节点</el-button
        >
      </div>
    </el-form>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import { vxRule } from "@/utils/validator";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      acountMode: "add",
      ruleForm: {
        approvalType: "",
        earlyApproval: "",
        finalApproval: "",
      },
      rules: {
        approvalType: vxRule(true, null, "change", "请选择功能节点"),
      },
      approvalTypeLists: [],
      managerLists: [],
      array: [1], //创建一个数组
      form: {
        value: [], //接收每个input框的值
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 添加按钮
    add() {
      this.array.push(1); //通过添加array的值，增加input的个数
    },
    del(index) {
      this.form.value.splice(index, 1); //先删除form中value对应索引的值
      this.array.splice(index, 1); //然后删除array对应索引的值，实现点击删除按钮，减少input框效果
    },
    submitForm(formName, a) {
      this.$refs[formName].validate((valid) => {
        if (a > 0) {
          // console.log(	ruleForm.approvalType);

          window.location.href = "http://118.31.122.89:9001/#/";
        } else {
          if (valid) {
            let api =
              this.acountMode == "add"
                ? this.$api.addApprovalApi
                : this.$api.editApprovalApi;

            api(this.ruleForm).then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  onClose: () => {
                    this.$router.go(-1);
                  },
                });
              }
            });
          } else {
            return false;
          }
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取管理员列表
    getManagerLists() {
      this.$api.getManagerListsApi().then((res) => {
        this.managerLists = res.data;
      });
    },
    // 获取审批类型列表
    getApprovalTypeLists() {
      this.$api.getApprovalTypeListsApi().then((res) => {
        this.approvalTypeLists = res.data;
      });
    },
    // 获取审批详情
    getApprovalDetails() {
      this.$api
        .getApprovalDetailsApi({ id: this.$route.query.id })
        .then((res) => {
          this.ruleForm.approvalType = res.data.approvalType;
          res.data.earlyApprovalId == 0
            ? (this.ruleForm.earlyApproval = "")
            : (this.ruleForm.earlyApproval = res.data.earlyApprovalId);
          res.data.finalApprovalId == 0
            ? (this.ruleForm.finalApproval = "")
            : (this.ruleForm.finalApproval = res.data.finalApprovalId);
          this.ruleForm.id = res.data.id;
        });
    },
  },
  created() {
    if (this.$route.name == "editApproval") {
      this.acountMode = "edit";
    }
    if (this.acountMode == "edit") {
      this.getApprovalDetails();
    }
    this.getManagerLists();
    this.getApprovalTypeLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .image-slot {
  font-size: 28px;
  color: #ccc;
}

.el-checkbox {
  height: 48px;
  line-height: 48px;
}

.info-avatar {
  width: 90px;
  height: 90px;
  margin-right: 25px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f5f7fa;
  border: 1px solid #f5f7fa;

  .avatar {
    width: 90px;
    height: 90px;
  }

  .tips {
    bottom: 0;
    height: 25px;
    left: 0;
    right: 0;
    line-height: 20px;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }
}

/deep/ .el-form {
  .el-select {
    width: 100%;
  }

  .el-button,
  .el-form-item__label,
  .el-input__inner {
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }

  .el-button,
  .reset {
    width: 150px;
    margin: 10px auto 0;
    padding: 0;
  }

  .reset {
    margin-left: 30px;
    border: none;
    background-color: $-color-border;
  }
}

.el-select-dropdown__item {
  font-size: 16px;
  height: 44px;
  line-height: 44px;
}
</style>
